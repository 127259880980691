<template>
  <div style="height:100vh;display:flex;justify-content:center;padding-top:20px">
    <div>
      <van-icon name="checked" color="#00BB00" size="120"/>
      <div style="text-align:center;margin-top:40px;font-size:20px">登记成功</div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>
<style scoped>

</style>